<template>
  <div
    :class="{
      content: true,
      page: page.total > queryParams.Limit,
    }"
    ref="content"
  >
    <div class="main-content">
      <div class="search-box" ref="search">
        <a-form>
          <a-row>
            <a-col :sm="5" :xxl="4">
              <e-input
                title="菜品名称"
                :labelCol="8"
                :valueCol="16"
                v-model:value="queryParams.Name"
              />
            </a-col>
            <a-col :sm="5" :xxl="4">
              <e-tree-select
                :labelCol="8"
                :valueCol="16"
                title="菜品类型"
                :fieldNames="replaceFields"
                v-model:value="queryParams.DishTypeId"
                :options="treeData"
              />
            </a-col>
            <a-col :sm="5" :xxl="4">
              <e-radio
                title="菜品状态"
                :labelCol="8"
                :valueCol="16"
                v-model:value="queryParams.IsOn"
                :options="[
                  {
                    label: '下架',
                    value: false,
                  },
                  {
                    label: '上架',
                    value: true,
                  },
                ]"
              />
            </a-col>
            <a-col :sm="5" :xxl="4">
              <e-radio
                title="是否人均"
                :labelCol="8"
                :valueCol="16"
                v-model:value="queryParams.IsPersonal"
                :options="[
                  {
                    label: '常规',
                    value: false,
                  },
                  {
                    label: '人均',
                    value: true,
                  },
                ]"
              />
            </a-col>
            <a-col>
              <a-button type="primary" @click="getDataList(1)"> 查询 </a-button>
              <a-button
                type="primary"
                @click="reset(() => resetFun())"
                danger
                style="margin-left: 10px"
              >
                重置
              </a-button>
              <a-button
                type="primary"
                style="margin-left: 10px"
                @click="$router.push({ name: 'hotel-dish-form' })"
              >
                添加菜品
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="data-list">
        <a-row :gutter="[16, 20]">
          <a-col :sm="6" :xxl="4" v-for="(v, k) in list" :key="k">
            <a-card hoverable>
              <template #cover>
                <div class="image">
                  <img alt="example" :src="$filters.image(v.pic)" />
                </div>
              </template>
              <template #actions>
                <a-switch
                  checked-children="上架"
                  un-checked-children="下架"
                  v-model:checked="v.isOn"
                  @change="changeStatus(v.id, $event)"
                />
                <a-tooltip>
                  <template #title>编辑</template>
                  <a
                    @click.stop="
                      $router.push({
                        name: 'hotel-dish-form-edit',
                        params: { id: v.id },
                      })
                    "
                  >
                    <EditOutlined />
                  </a>
                </a-tooltip>
                <a-tooltip>
                  <template #title>删除</template>
                  <a @click.stop="delForm(() => delFormFun(v))">
                    <DeleteOutlined />
                  </a>
                </a-tooltip>
              </template>
              <a-card-meta
                :title="v.name"
                :description="'售价：¥' + v.sellingPrice"
              />
            </a-card>
          </a-col>
        </a-row>
      </div>
    </div>

    <div class="pagination">
      <a-pagination
        :total="page.total"
        v-model:current="page.page"
        v-model:page-size="queryParams.Limit"
        :hide-on-single-page="true"
        :show-size-changer="true"
        :page-size-options="['24', '48', '120', '240']"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
      >
        <template #itemRender="{ page, type }">
          <a v-if="type === 'prev'" class="ant-pagination-item-link">上一页</a>
          <a v-else-if="type === 'next'" class="ant-pagination-item-link">
            下一页
          </a>
          <span v-else>{{ page }}</span>
        </template>
      </a-pagination>
      <span>数据总数：{{ page.total }}</span>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, createVNode } from 'vue'
import {
  EditOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined
} from '@ant-design/icons-vue'
import { DishClass } from '@/apis/dish'
import page from '@/mixins/page'
import { dataToTree } from '@/utils'

const api = new DishClass()
export default defineComponent({
  mixins: [page],
  components: {
    EditOutlined,
    DeleteOutlined
  },
  setup () {
    const loading = ref(false)
    const height = ref(500)
    const width = ref(500)
    const visiblePassword = ref(false)

    const id = ref('')
    const treeData = ref([])
    const units = ref([])

    const parentIds = ref([])
    const cateOptions = ref([])

    const replaceFields = {
      value: 'id',
      key: 'id'
    }

    const queryParams = ref({
      Limit: 24,
      IsOn: true
    })

    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 60
      },
      {
        title: '菜品名称',
        key: 'name',
        dataIndex: 'name'
      },
      {
        title: '成本价',
        key: 'price',
        dataIndex: 'price',
        width: 100
      },
      {
        title: '售价',
        key: 'sellingPrice',
        dataIndex: 'sellingPrice',
        width: 100
      },
      {
        title: '状态',
        key: 'isOn',
        dataIndex: 'isOn',
        width: 80
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        width: 120
      }
    ]
    return {
      columns,
      loading,
      height,
      width,
      visiblePassword,
      treeData,
      replaceFields,
      id,
      units,
      queryParams,
      parentIds,
      cateOptions
    }
  },
  created () {
    this.getDataList(1)
    this.getCategorys()
  },
  methods: {
    changeDishType (e) {
      this.queryParams.DishTypeId = e[e.length - 1]
      this.getDataList(1)
    },
    async getCategorys () {
      const list =
        this.$store.state.data.ALL_DISHTYPES.length > 0
          ? this.$store.state.data.ALL_DISHTYPES
          : await this.$store.dispatch('GetAllDishTypes')

      const data = dataToTree(list, 0)
      this.treeData = data
    },
    getDataList (page) {
      this.loading = true
      this.page.page = page
      const q = { ...this.queryParams }
      q.Offset = (page - 1) * q.Limit

      q.Type = 0
      api.gets(q).then((resp) => {
        this.list = resp.data
        this.page.total = resp.total
        this.loading = false
        this.getTableHeight()
      })
    },
    resetFun () {
      this.queryParams = {
        Limit: 24
      }

      this.getDataList(1)
    },
    delFormFun (record) {
      const _this = this
      _this.$confirm({
        title: '确定删除?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          `即将删除菜品：${record.name}`
        ),

        onOk () {
          api.del(record.id).then(() => {
            _this.$message.success('删除成功！')
            _this.getDataList(1)
          })
        }
      })
    },

    changeStatus (id, state) {
      api.state(id, state).then(() => {
        this.$message.success('修改成功')
        this.getDataList(this.page.page)
      })
    },

    changeIsPersonal (id, state) {
      api.setPersonal(id, state).then(() => {
        this.$message.success('修改成功')
        this.getDataList(this.page.page)
      })
    },
    changeIsMust (id, state) {
      api.setMust(id, state).then(() => {
        this.$message.success('修改成功')
        this.getDataList(this.page.page)
      })
    },

    getTableHeight () {
      const paddingBottom = this.page.total > this.queryParams.Limit ? 50 : 9
      const mainPadding = 30
      const thead = 56
      this.height =
        this.$refs.content.offsetHeight -
        this.$refs.search.offsetHeight -
        thead -
        paddingBottom -
        mainPadding

      this.width = this.$refs.search.offsetWidth - 10
    }
  }
})
</script>

<style lang="less" scoped>
.image {
  width: 100%;
  height: 200px;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
  }
}
</style>
